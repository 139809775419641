import React from "react";
import "./loading.scss";

const Loading = () => (
  <div className="loading-container">
    <div className="loading-content">
      <div className="spinner" />
      <p className="loading-text">Carregando permissões...</p>
    </div>
  </div>
);

export default Loading;
