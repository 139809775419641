import { combineReducers } from "redux";
import UserReducer from "./userReducer";
import GroupReducer from "./groupReducer";
import ScheduleReducer from "./scheduleReducer";
import ReceivableReducer from "./receivableReducer";
import OrderReducer from "./orderReducer";
import SubscriptionReducer from "./subscriptionReducer";
import DashboardReducer from "./dashboardReducer";
import TransactionReducer from "./transactionReducer";
import BranchReducer from "./branchReducer";
import PermissionsReducer from "./permissionReducer";

export default (asyncReducers) =>
  combineReducers({
    userState: UserReducer,
    groupState: GroupReducer,
    scheduleState: ScheduleReducer,
    receivableState: ReceivableReducer,
    orderState: OrderReducer,
    subscriptionState: SubscriptionReducer,
    dashboardState: DashboardReducer,
    transactionState: TransactionReducer,
    branchState: BranchReducer,
    permissionsState: PermissionsReducer,
    ...asyncReducers,
  });
