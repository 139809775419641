/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media,
  Label,
} from "reactstrap";
import validator from "validator";
import {
  authDataUser,
  authDataFirstLogin,
  clearAuth,
  authWithSSO,
} from "../../actions/user";
import { localSet, localRemove, localGet } from "../../lib/session";
import Loading from "../utils/loading";
import Model from "../../model/login";
import { modelToAuthViewModel } from "../../mapper/user";
import Config from "../../config/environments";
import LOGIN from "../../const/login";
import { AUTH_TOKEN, IS_LOGGED, WHITELABEL } from "../../actionTypes/auth";
import { getUserPermissions } from "../../actions/permissions";
import { passwordValidator } from "../../lib/customValidade";

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResize = this.handleResize.bind(this);
    const { history } = this.props;
    this.state = {
      ...Model,
      Redirected: false,
      ShowInfo: true,
      IsFirstLogin: history.location.pathname === "/primeiro-login",
      Width: window.innerWidth,
      isAuthWithSSO: false,
      showSignForm: false,
    };
  }

  componentDidMount() {
    localRemove(AUTH_TOKEN);
    localRemove(WHITELABEL);
    localRemove(IS_LOGGED);

    window.addEventListener("resize", this.handleResize.bind(this));

    this.handleSubmitVerifyExistsUrlSSO();
    this.updateProviderFromUrl();
    this.handleAuthenticationFromUrl();
    this.checkAndUpdateSSOButtonVisibility(this.props.authUser);

    window.addEventListener("hashchange", () => {
      this.handleAuthenticationFromUrl();
      this.updateProviderFromUrl();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { authUser, history } = nextProps;
    const { Redirected, IsFirstLogin } = nextState;
    const { NewPassword, ConfirmPassword } = this.state;
    const user = authUser && authUser.data;
    const { location } = history;
    const { pathname } = location;

    if (authUser && authUser.state === false && Redirected) {
      if (authUser && authUser.data && authUser.success === true) {
        localSet(AUTH_TOKEN, authUser.data, Config.EXPIRATION_AUTH);
        localSet(IS_LOGGED, "true", Config.EXPIRATION_AUTH);
        window.open("/", "_self");
      }

      if (authUser && user && user.Error === "FIRST_LOGIN") {
        this.setState({ Redirected: false, IsFirstLogin: true });
        nextProps.clearAuth();
        history.push("/primeiro-login");
      }
    }

    if (
      (pathname === "/primeiro-login" && !IsFirstLogin) ||
      (pathname === "/login" && IsFirstLogin)
    ) {
      this.setState({ IsFirstLogin: pathname === "/primeiro-login" });
    }

    if (
      nextState.NewPassword !== NewPassword ||
      nextState.ConfirmPassword !== ConfirmPassword
    ) {
      passwordValidator(nextState.NewPassword, nextState.ConfirmPassword);
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    // Check if URL hash changed
    if (prevProps.location !== this.props.location) {
      this.handleAuthenticationFromUrl();
    }
    if (prevProps.location !== this.props.location) {
      this.handleAuthenticationFromUrl();
    }

    if (prevProps.authUser !== this.props.authUser) {
      this.checkAndUpdateSSOButtonVisibility(this.props.authUser);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
    window.removeEventListener("hashchange", () => {
      this.handleAuthenticationFromUrl();
      this.updateProviderFromUrl();
    });
  }

  handleAuthenticationSuccess = (decodedAuth) => {
    try {
      const expirationTime = 3600000; // 1 hour
      localSet(AUTH_TOKEN, decodedAuth, expirationTime);
      localSet(IS_LOGGED, "true", expirationTime);
      const storedAuth = localGet(AUTH_TOKEN);
      const isLogged = localGet(IS_LOGGED);
      if (storedAuth && isLogged) {
        this.props.getUserPermissions();
        this.props.history.push("/dashboard");
      }
    } catch (error) {
      console.error("Redirect error", error);
      this.props.history.push("/login");
    }
  };

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const { IsFirstLogin } = this.state;

    const state = modelToAuthViewModel(this.state);

    if (IsFirstLogin) {
      p.authDataFirstLogin(state);
    } else {
      p.authDataUser(state);
    }

    this.setState({ Redirected: true });
  }

  handleSubmitVerifyExistsUrlSSO = () => {
    this.props.authWithSSO();
  };

  updateProviderFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(
      window.location.hash.split("?")[1] || ""
    );
    const provider = searchParams.get("provider") || hashParams.get("provider");

    this.setState({
      showSignForm: provider === "pay2b",
    });
  };

  handleSubmitWithSSO = (event) => {
    event.preventDefault();
    const { authUser } = this.props;

    if (authUser?.data?.url) {
      window.location.href = authUser.data.url;
    } else {
      console.error("URL not found in response");
      this.props.authWithSSO();
    }
  };

  handleAuthenticationFromUrl = () => {
    try {
      const hasParams = window.location.hash.split("?")[1];
      if (!hasParams) {
        return;
      }
      const urlParams = new URLSearchParams(hasParams);
      const authentication = urlParams.get("authentication");
      if (!authentication) {
        return;
      }

      const decodedAuth = JSON.parse(atob(authentication));

      if (decodedAuth.accessToken) {
        const transformedJson = {
          MerchantId: decodedAuth.user.merchantId,
          UserId: decodedAuth.user.userId,
          Name: decodedAuth.user.fullname,
          AccessToken: decodedAuth.accessToken,
          IdToken: decodedAuth.idToken,
        };
        this.handleAuthenticationSuccess(transformedJson);
      }
    } catch (error) {
      console.error("Error processing authentication", error);
    }
  };

  checkAndUpdateSSOButtonVisibility(authUser) {
    const showSSOButton =
      authUser &&
      authUser.data &&
      authUser.data.url &&
      authUser.data.url !== "";

    if (this.state.isAuthWithSSO !== showSSOButton) {
      this.setState({ isAuthWithSSO: showSSOButton });
    }
  }

  render() {
    const { authUser } = this.props;
    const { Email, ShowInfo, IsFirstLogin, NewPassword, Width } = this.state;
    let { hasNumbers, hasUpperCase, hasSpecialChar, isLongEnough } = this.state;
    const isMobile = Width < 999;

    const env =
      process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();

    if (authUser && authUser.state !== false) {
      return <Loading msg={LOGIN.LOADING} />;
    }

    hasNumbers = validator.matches(NewPassword, /.*[0-9].*/g);
    hasUpperCase = validator.matches(NewPassword, /[A-Z]/g);
    hasSpecialChar = validator.matches(
      NewPassword,
      /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g
    );
    isLongEnough = validator.isLength(NewPassword, { min: 8 });

    return (
      <div className="app back-logo flex-row align-items-center">
        {env === "HML" && ShowInfo && (
          <>
            <Row className="identifier-environmment top-0">
              <Col xs="1" />
              <Col xs="10" className="d-flex justify-content-center">
                Você está acessando o ambiente de teste !
              </Col>
              <Col xs="1" className="p-0">
                <Button
                  className="m-0 bg-transparent border-0"
                  type="button"
                  onClick={() => {
                    this.setState({ ShowInfo: false });
                  }}
                  onKeyDown={() => {
                    this.setState({ ShowInfo: false });
                  }}
                >
                  <i className="fa fa-close text-white" />
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 border-top">
                    <CardBody>
                      <Row>
                        <Col
                          xs={isMobile ? 6 : 5}
                          className={`d-flex justify-content-end ${
                            isMobile ? "" : "p-0"
                          }`}
                        >
                          <Media
                            className="logo"
                            object
                            src="/assets/imgs/pay2b Logo_pay2b-07.png"
                            alt="Logo"
                            style={{ width: "100px" }}
                          />
                        </Col>
                        <Col
                          xs={isMobile ? 6 : 7}
                          className="d-flex flex-column justify-content-center p-0"
                        >
                          <span className="title">{LOGIN.TITLE}</span>
                          <span className="sub-title">{LOGIN.SUB_TITLE}</span>
                        </Col>
                      </Row>
                      <Row className="justify-content-center text-center">
                        <Col md="12">
                          <h1>
                            {IsFirstLogin ? LOGIN.FIRST_LOGIN : LOGIN.LOGIN}
                          </h1>
                        </Col>
                        <Col md="12">
                          <p className="text-muted">{LOGIN.LOGIN_TEXT}</p>
                        </Col>
                      </Row>
                      <hr />
                      {IsFirstLogin && (
                        <Row className="justify-content-center text-center">
                          <Col md="12 mb-3">
                            <Label style={{ fontSize: "18px" }}>
                              {LOGIN.TITLE_VALIDATION}
                            </Label>
                            <ul className="validation">
                              <li
                                className={`validation--item ${
                                  hasUpperCase ? "valid" : "invalid"
                                }`}
                              >
                                {LOGIN.VALIDATION_UPPER}
                              </li>
                              <li
                                className={`validation--item ${
                                  hasSpecialChar ? "valid" : "invalid"
                                }`}
                              >
                                {LOGIN.VALIDATION_SPECIAL}
                              </li>
                              <li
                                className={`validation--item ${
                                  hasNumbers ? "valid" : "invalid"
                                }`}
                              >
                                {LOGIN.VALIDATION_NUMBER}
                              </li>
                              <li
                                className={`validation--item ${
                                  isLongEnough ? "valid" : "invalid"
                                }`}
                              >
                                {LOGIN.VALIDATION_CHARACTERS}
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      )}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          required="required"
                          id="Email"
                          name="Email"
                          value={Email}
                          maxLength="50"
                          onChange={(e) =>
                            this.setState({ Email: e.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder={IsFirstLogin ? "Senha atual" : "Senha"}
                          required="required"
                          id="Password"
                          name="Password"
                          maxLength="256"
                          onChange={(e) =>
                            this.setState({ Password: e.target.value })
                          }
                        />
                      </InputGroup>
                      {IsFirstLogin && (
                        <>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Nova senha"
                              required="required"
                              id="NewPassword"
                              name="NewPassword"
                              className="password-validator"
                              maxLength="256"
                              onChange={(e) =>
                                this.setState({ NewPassword: e.target.value })
                              }
                            />
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Confirmar nova senha"
                              required="required"
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              maxLength="256"
                              onChange={(e) =>
                                this.setState({
                                  ConfirmPassword: e.target.value,
                                })
                              }
                            />
                          </InputGroup>
                        </>
                      )}
                      <Row>
                        {!IsFirstLogin && (
                          <Col xs="12" className="text-right">
                            <a
                              href="/#/esqueci-minha-senha"
                              className="p-2"
                              id="ForgotButton"
                              name="ForgotButton"
                            >
                              {LOGIN.FORGOT_BUTTON}
                            </a>
                          </Col>
                        )}
                        <Col xs="12">
                          <Button
                            color="primary"
                            className="px-4 bg-p2b border-0 mx-0"
                            style={{ width: "100%" }}
                          >
                            {LOGIN.LOGIN_BUTTON}
                          </Button>
                        </Col>
                        {this.state.isAuthWithSSO && (
                          <Col xs="12">
                            <Button
                              type="button"
                              color="secondary"
                              className="px-4 bg-p3b border-1 mx-0"
                              style={{ width: "100%" }}
                              onClick={this.handleSubmitWithSSO}
                            >
                              {LOGIN.LOGIN_WITH_SSO}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({ state: PropTypes.bool }).isRequired,
  authDataUser: PropTypes.func.isRequired,
  authWithSSO: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({
      Error: PropTypes.string,
      url: PropTypes.string,
    }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  authDataFirstLogin: PropTypes.func.isRequired,
  clearAuth: PropTypes.func.isRequired,
  getUserPermissions: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authDataUser,
      authDataFirstLogin,
      clearAuth,
      authWithSSO,
      getUserPermissions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
