/* eslint-disable no-console */
import { axiosInstance as axios } from "../lib/axios";
import Config from "../config/environments";
import { GET_USER_PERMISSIONS } from "../actionTypes/permissions";

export const getUserPermissions = () => async (dispatch) => {
  dispatch({
    type: GET_USER_PERMISSIONS,
    state: true,
    payload: null,
  });

  try {
    const url = `${Config.USER_PERMISSION.URL}`;
    const response = await axios.get(url);

    dispatch({
      type: GET_USER_PERMISSIONS,
      state: false,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error fetching permissions:", error);
    dispatch({
      type: GET_USER_PERMISSIONS,
      state: false,
      error,
    });
  }
};
