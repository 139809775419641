import {
  AUTH_USER,
  POST_USER,
  LIST_USER,
  FORGOT_PASSWORD,
  AUTH_WITH_SSO,
} from "../actionTypes/user";

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostUser: BASE,
  listUser: BASE_PAGINATION,
  authUser: BASE,
  forgotPassword: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authUser: action.payload };
    case POST_USER:
      return { ...state, statePostUser: action.payload };
    case AUTH_WITH_SSO:
      return { ...state, authUser: action.payload };
    case LIST_USER:
      return { ...state, listUser: action.payload };
    case FORGOT_PASSWORD:
      return { ...state, forgotPassword: action.payload };
    default:
      return state;
  }
};
