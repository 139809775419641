export default {
  LOADING: "Carregando...",
  TITLE: "Bem vindo ao",
  SUB_TITLE: "Portal do Merchant",
  LOGIN: "Faça seu login",
  FIRST_LOGIN: "Faça seu primeiro login",
  TITLE_VALIDATION: "Sua senha deve conter no mínimo",
  VALIDATION_UPPER: "Uma letra maiúscula",
  VALIDATION_SPECIAL: "Um caractere especial",
  VALIDATION_NUMBER: "Um número",
  VALIDATION_CHARACTERS: "8 caracteres",
  LOGIN_TEXT: "",
  LOGIN_BUTTON: "ENTRAR",
  FORGOT_BUTTON: "Esqueci minha senha",
  FORGOT_MSG:
    "Para recuperar sua senha, entre com contato com o Administrador do Sistema.",
  LOGIN_WITH_SSO: "Entrar com SSO",
};
