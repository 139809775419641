import moment from 'moment';
import Model from '../model/user';
import { DocumentFormat } from '../lib/mask';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.IsAdmin = view.admin;
    model.Uuid = view.uuid;
    model.Email = view.email;
    model.Name = view.fullName ? view.fullName : view && view.user && view.user.fullName;
    model.ProviderUserId = view.providerUserId;
    model.MerchantId = view && view.user && view.user.merchantId;
    model.Error = view.error;
    model.AccessToken = view.accessToken;
    model.Groups = view.groups;
    model.CreatedAt = view.createdAt && moment(view.createdAt).format('L');

    return model;
  } catch (error) {
    throw new Error(`UserMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.users.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const viewsPag = {
      total: models.users.totalPages,
      list: views,
    };

    return viewsPag;
  } catch (error) {
    throw new Error(`UserMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    const groups = model.Checked.filter((value) => value !== '' && typeof value === 'string');

    view.fullName = model.Name && model.Name.trim();
    view.email = model.Email;
    view.uuid = model.Uuid;
    view.groups = groups;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`UserMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.ResponseStatus = view.ResponseStatus;
    model.Uuid = view.Uuid;
    model.Email = view.Email;
    model.Name = view.Name;
    model.Type = view.Type;
    model.Password = view.Password || '';
    model.Document = DocumentFormat(view.Document);
    model.Status = view.Status;
    model.Groups = view.Groups;

    return model;
  } catch (error) {
    throw new Error(`UserMapper - viewModelToState = ${error}`);
  }
};

export const modelToAuthViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.email = model.Email;
    view.password = model.NewPassword ? model.NewPassword : model.Password;

    if (model.NewPassword && model.ConfirmPassword) {
      view.oldPassword = model.Password;
      view.confirmPassword = model.ConfirmPassword;
    }

    return view;
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`);
  }
};

export const modelToResetViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.email = model.Email;
    view.password = model.Password;
    view.confirmPassword = model.ConfirmPassword;
    view.code = model.Code;

    return view;
  } catch (error) {
    throw new Error(`UserMapper - modelToAuthViewModel = ${error}`);
  }
};

export const modelToModel = (obj, checked) => {
  try {
    if (!obj) return null;

    let group = [];
    let idxs = [];
    const checks = checked;
    const text = ['admin', 'dashboard', 'schedule', 'sales', 'order', 'subscriptions'];

    text.forEach((x, idx) => {
      obj.Groups.filter((y) => {
        group = y.includes(x) === true ? [...group, y] : group;
        idxs = y.includes(x) === true ? [...idxs, idx] : idxs;

        return y.includes(x);
      });
    });

    group.forEach((check, idx) => {
      checks[idxs[idx]] = check;
    });

    checks.forEach((check, idx) => {
      if (check === '' && idx > 0) {
        checks[idx] = null;
      }
    });

    return checks;
  } catch (error) {
    throw new Error(`UserMapper - modelToModel = ${error}`);
  }
};

export const modelToFilter = (model, whitelabel) => {
  try {
    if (!model) return null;

    let view = '';

    view += whitelabel && whitelabel.data && whitelabel.data !== 'ALL' ? `whitelabel_ids=${whitelabel.data}&` : '';
    view += model.FilterName ? `name=${encodeURIComponent(model.FilterName)}&` : '';
    view += model.FilterEmail ? `email=${encodeURIComponent(model.FilterEmail)}&` : '';
    view += model.Permission && model.Permission !== 'ALL' ? `groups=${encodeURIComponent(model.Permission)}&` : '';
    view += `page=${model.Page + 1}&`;
    view += `quantity=${model.PageSize}`;

    return view;
  } catch (error) {
    throw new Error(`OrderMapper - ModelToFilter = ${error}`);
  }
};
