import { axiosInstance as axios, axiosLogin } from "../lib/axios";
import Config from "../config/environments";
import { viewModelToModel, viewsModelToModels } from "../mapper/user";
import {
  AUTH_USER,
  LIST_USER,
  POST_USER,
  FORGOT_PASSWORD,
  AUTH_WITH_SSO,
} from "../actionTypes/user";
import updateState from "./common/common";

export const getDataUser = (data) => async (dispatch) => {
  const base = {
    type: LIST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.USER.URL}${Config.USER.USER}${data}`;

  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewsModelToModels(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const clearDataUser = () => async (dispatch) => {
  const base = {
    type: LIST_USER,
    payload: {
      state: false,
      data: null,
    },
    state: true,
    success: true,
  };

  dispatch(base);
};

export const authDataFirstLogin = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.URL}${Config.USER.FIRST_LOGIN}`;

  axiosLogin
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewModelToModel(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const authWithSSO = () => async (dispatch) => {
  const base = {
    type: AUTH_WITH_SSO,
    state: true,
    success: true,
    payload: {},
  };
  const url = `${Config.END_POINT}/sso/${Config.HOST_NAME}/authorization`;
  axios
    .get(url)
    .then((response) => {
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });
};

export const authDataUser = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.URL}${Config.USER.AUTH}`;

  axiosLogin
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewModelToModel(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const authDataLogout = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.URL}${Config.USER.LOGOUT}`;

  axiosLogin
    .post(url, {}, { headers: { "x-access-token": data } })
    .then((response) => {
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const forgotDataPassword = (data) => async (dispatch) => {
  const base = {
    type: FORGOT_PASSWORD,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.URL}${Config.USER.FORGOT_PASSWORD}/${data}`;

  axiosLogin
    .post(url)
    .then((response) => {
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const resetDataPassword = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.URL}${Config.USER.FORGOT_PASSWORD}${Config.USER.CONFIRM}`;

  axiosLogin
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewModelToModel(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const setStatusAuth = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    payload: { data, state: false },
  };

  dispatch(base);
};

export const clearAuth = () => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    payload: {
      state: false,
      data: null,
    },
    state: true,
    success: true,
  };

  dispatch(base);
};

export const postDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.USER.URL}${Config.USER.USER}`;

  axios
    .post(url, data)
    .then((response) => {
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const clearStateUser = () => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {
      data: null,
    },
    state: true,
    success: true,
  };

  dispatch(base);
};

export const patchDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.USER.URL}${Config.USER.USER}/${data.uuid}`;

  axios
    .patch(url, data)
    .then((response) => {
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const deleteDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.USER.URL}${Config.USER.USER}/${data}`;

  axios
    .delete(url)
    .then((response) => {
      base.payload = updateState(response);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};
